export const CLOSE = "장마감"
export const CLOSE_ENG = "Closed";
export const FIVEMIN_DELAY = "5분 지연";
export const FIVEMIN_DELAY_ENG = "5mins Delayed";
export const DAY_DELAY = "하루 지연";
export const DAY_DELAY_ENG = "24hrs Delayed";
export const AFTER_MARKET_FIN_MIN_DELAY = "애프터마켓 5분 지연";

export const sortText = [
    ["거래량 많은 순", "Large Volume"],
    ["시가총액 높은 순", "Large Market Cap"],
    ["하락 전망 높은 순", "High Loss-forecast"],
    ["하락 전망 낮은 순", "Low Loss-forecast"],
]

export const INTEREST_SORT = [['추가된 날짜 순', 'Added Date'],

['이름 순', 'Name']];

export const SAFE = 'Safe';
export const SAFE_KR = '안전';
export const MODEREATE = 'Moderate';
export const MODEREATE_KR = '적정';
export const CAUTION = 'Caution';
export const CAUTION_KR = '주의';
export const DANGER = 'Danger';
export const DANGER_KR = '위험';


export const AI = 'AI';
export const RISK = 'Risk';
export const PORTFOLIO = 'Portfolio';
export const ASSETINFO = 'Asset Info';

export type StabilityInfo = {
    안정: string;
    안정eng?: string;
    불안정: any;
    불안정eng?: any;
    영업현금흐름?: string;
    단기차입금?: string;
    금융비용?: string;
    부채총계?: string;
    자본총계?: string;
    매출액?: string;
    재고자산?: string;
    자산총계?: string;
    유동자산?: string;
    매출총이익?: string;
}

interface DetailFinStabilityDesc {
    field: string;
    fieldEng: string;
    title: string;
    titleEng: string;
    현금흐름보상비율?: StabilityInfo;
    부채비율?: StabilityInfo;
    재고자산회전율?: StabilityInfo;
    총자산회전율?: StabilityInfo;
    유동자산회전율?: StabilityInfo;
    매출총이익률?: StabilityInfo;
    자산수익률?: StabilityInfo;
    총자산영업이익률?: StabilityInfo;
    "3년간 영업현금흐름"?: StabilityInfo;
}
export const DETAIL_FIN_STABILITY_DESC_TABLE: DetailFinStabilityDesc[] = [
    {
        field: "안정성",
        fieldEng: "Stable",
        title: "빚을 갚을 수 있는 능력",
        titleEng: "Debt repayment capacity",
        현금흐름보상비율: {
            안정: "번 돈으로 빚을 관리할 수 있어요",
            안정eng: "Can manage debt with earnings",
            불안정: '번 돈에 비해 빚이 많아요',
            불안정eng: 'High debt relative to earnings',
            영업현금흐름: "사업에서 발생한 현금",
            단기차입금: "1년 이내에 갚아야 할 빚",
            금융비용: "대출이나 채권 발행에 따른 이자비용"
        },
        부채비율: {
            안정: "빚의 규모를 잘 관리하고 있어요",
            안정eng: "Managing debt level well",
            불안정: ["부채를 활용한 성장 기회를 놓치고 있어요", "가진 돈에 비해 빚이 많아요"],
            불안정eng: ['Missing growth opportunities by not leveraging debt', 'High debt relative to assets'],
            부채총계: "갚아야 할 모든 빚",
            자본총계: "빌린 돈을 제외한 가지고 있는 모든 돈"
        }
    },
    {
        field: "활동성",
        fieldEng: "Active",
        title: "자산 관리의 효율성",
        titleEng: "Inventory turnover efficiency",
        재고자산회전율: {
            안정: "재고가 빠르게 수익으로 연결돼요",
            안정eng: "Inventory quickly converts to revenue",
            불안정: "팔리지 않은 재고가 쌓여 있어요",
            불안정eng: 'Accumulating unsold inventory',
            매출액: "상품이나 서비스를 팔아서 번 돈",
            재고자산: "판매하고 남은 재고의 가치"
        },
        총자산회전율: {
            안정: "모든 자산이 잘 돌아가고 수익을 내요",
            안정eng: "Efficiently utilizing assets and generating revenue",
            불안정: "자산은 많은데 매출이 따라오지 않아요",
            불안정eng: 'Significant assets but lagging revenue',
            매출액: "상품이나 서비스를 팔아서 번 돈",
            자산총계: "빌린 돈을 포함한 가지고 있는 모든 돈"
        },
        유동자산회전율: {
            안정: "자산을 활발하게 사용해서 매출을 내요",
            안정eng: "Actively using assets to generate sales",
            불안정: "현금이나 기타 자산이 묶여 있어요",
            불안정eng: 'Cash or other assets tied up',
            매출액: "상품이나 서비스를 팔아서 번 돈",
            유동자산: "1년 내로 현금화 할 수 있는 자산"
        },
        자산수익률: {
            안정: "자산을 활발하게 사용해서 매출을 내요",
            안정eng: "Actively using assets to generate sales",
            불안정: "현금이나 기타 자산이 묶여 있어요",
            불안정eng: 'Cash or other assets tied up',
        }

    },
    {
        field: "수익성",
        fieldEng: "Profit",
        title: "매출에서 발생하는 이익",
        titleEng: "Profit generated from sales",
        매출총이익률: {
            안정: " 판매를 통해 큰 이익을 남겨요",
            안정eng: "Generating high profits through sales",
            불안정: " 판매하고 남는 이익이 적어요",
            불안정eng: 'Low profit margin on sales',
            매출액: "상품이나 서비스를 팔아서 번 돈",
            매출총이익: "판매 후 남은 이익"
        },
        총자산영업이익률: {
            안정: " 판매를 통해 큰 이익을 남겨요",
            안정eng: "Generating high profits through sales",
            불안정: " 판매하고 남는 이익이 적어요",
            불안정eng: 'Low profit margin on sales',
        },
    },
    {
        field: "현금흐름",
        fieldEng: "Cash",
        title: "영업을 통한 현금 확보 능력",
        titleEng: "Cash generation ability",
        "3년간 영업현금흐름": {
            안정: "",
            불안정: ""
        },
    },
]

export type STRATEGY_TYPE = 'rpn' | 'ri5' | 'km7' | 'rs3' | 'fs7'

export const SERVICE_STRATEGY_CODES = ['km7', 'fs7', 'rs3'] as STRATEGY_TYPE[];

type STRATEGY_META_TYPE = {
    [key in STRATEGY_TYPE]: {
        country: string;
        countryEng: string;
        industry: string;
        industryEng: string;
        standard: string;
        standardEng: string;
        name: string;
        desc?: string;
        engName: string;
        recommandTitle: string;
        recommandTitleEng: string;
        recommandSubtitle: string;
        recommandSubtitleEng: string;
        recommandContentTitle: string;
        recommandContentTitleEng: string;
        recommandContentDesc1: string;
        recommandContentDescEng1: string;
        recommandContentDesc2: string;
        recommandContentDescEng2: string;
        recommandType: string;
        recommandTypeEng: string;
    };
};

export const STRATEGY_META: STRATEGY_META_TYPE = {
    'rpn': {
        name: "리스크웨더 Pro",
        desc: '따라하면 돈 버는 투자 전략',
        engName: "Risk Weather Pro",
        country: "대한민국",
        countryEng: "Korea",
        industry: "전체",
        industryEng: "All",
        standard: "높은 투자 매력도",
        standardEng: "High Investment Attractiveness",
        recommandTitle: "전략 설명",
        recommandTitleEng: "strategy",
        recommandSubtitle: "Mathew Lee 연구원",
        recommandSubtitleEng: "Mathew Lee 연구원",
        recommandContentTitle: "RPN 전략",
        recommandContentTitleEng: "RPN 전략",
        recommandContentDesc1: "Riskweather AI가 분석한 투자매력도가 가장 높은 종목으로 구성되는 전략입니다.",
        recommandContentDescEng1: "Riskweather AI가 분석한 투자매력도가 가장 높은 종목으로 구성되는 전략입니다.",
        recommandContentDesc2: "성장하는 산업에서 리스크를 이겨내며 주가가 상승할 여력이 높은 기업에 투자합니다.",
        recommandContentDescEng2: "성장하는 산업에서 리스크를 이겨내며 주가가 상승할 여력이 높은 기업에 투자합니다.",
        recommandType: "",
        recommandTypeEng: ""
    },
    'ri5': {
        name: "뜨는 산업에 올라타기",
        desc: '상승세 강한 산업의 대장주',
        engName: "Leading stocks in high-momentum sectors",
        country: "대한민국",
        countryEng: "Korea",
        industry: "높은 상승 강도",
        industryEng: "High momentum",
        standard: `각 산업 내 대장주 5개`,
        standardEng: "Top market cap by industry",
        recommandTitle: "전략 설명",
        recommandTitleEng: "strategy",
        recommandSubtitle: "쎈 놈이 뜰 때 잡는 방법",
        recommandSubtitleEng: "How to Catch the Rising Stars",
        recommandContentTitle: "뜨는 산업에 올라타기・RI5",
        recommandContentTitleEng: "Leading stocks in high-momentum sectors・RI5",
        recommandContentDesc1: "최근 시장이 주목하는 산업을 선별하고, 해당 산업의 대장주에 투자합니다.",
        recommandContentDescEng1: "Identify industries gaining market attention and invest in the leading stocks within those sectors.",
        recommandContentDesc2: "트렌드 변화에 적극적으로 대응하고 싶은 투자자에게 적합합니다. ",
        recommandContentDescEng2: "This strategy is suitable for investors who want to actively respond to changing market trends.",
        recommandType: "공격투자형",
        recommandTypeEng: "aggressive investors"
    },
    'km7': {
        name: "믿을만한 대형주",
        desc: "계속 성장할 시가총액 상위 기업",
        engName: "Riding emerging industries",
        country: "대한민국",
        countryEng: "Korea",
        industry: "전체",
        industryEng: "All",
        standard: `투자 매력도 상위 7개\nKOSPI 200중`,
        standardEng: "High Investment Attractiveness\nFrom KOSPI 200",
        recommandTitle: "전략 설명",
        recommandTitleEng: "strategy",
        recommandSubtitle: "국장 생존 비법",
        recommandSubtitleEng: "Stock Market Survival Strategy",
        recommandContentTitle: "믿을만한 대형주・KM7",
        recommandContentTitleEng: "Riding emerging industries・KM7",
        recommandContentDesc1: "코스피 상위 200개 기업 중 재무가 탄탄하고 리스크를 이겨내며 성장할 여력이 높은 기업에 투자합니다.",
        recommandContentDescEng1: "Focuses on top 200 KOSPI-listed firms with strong financials, risk resilience, and high growth potential.",
        recommandContentDesc2: "불확실한 흐름의 국내 시장에서 변동성 낮추면서 수익을 내고 싶은 안정추구형 투자자에게 적합합니다.",
        recommandContentDescEng2: "Ideal for risk-neutral investors aiming for returns while managing volatility in an uncertain market.",
        recommandType: "안정추구형",
        recommandTypeEng: "conservative growth investors"
    },
    'rs3': {
        name: "안전 성장주",
        desc: "낮은 리스크로 꾸준한 성장이 기대되는 회사",
        engName: "Stable growth stocks",
        country: "대한민국",
        countryEng: "Korea",
        industry: "전체",
        industryEng: "All",
        standard: `투자 매력도 상위 3개\n낮은 하락 리스크 중`,
        standardEng: "High Investment Attractiveness\nFrom low downside risk",
        recommandTitle: "전략 설명",
        recommandTitleEng: "strategy",
        recommandSubtitle: "위험한 건 질색이라면",
        recommandSubtitleEng: "If you can't stomach risk",
        recommandContentTitle: "안전 성장주・RS3 ",
        recommandContentTitleEng: "Stable growth stocks・RS3",
        recommandContentDesc1: "리스크는 낮으면서 안정적인 수익이 기대되는 종목에 투자하는 전략입니다.",
        recommandContentDescEng1: "This is a strategy for investing in stocks that offer stable returns with low risk.",
        recommandContentDesc2: "단기간 높은 수익보다 꾸준히 오르는 종목에 투자하고 싶은 투자자에게 적합합니다.",
        recommandContentDescEng2: "It's suitable for investors who prefer steady growth over short-term high returns.",
        recommandType: "안정형",
        recommandTypeEng: "conservative investors"
    },
    'fs7': {
        name: "균형의 날개 펼치기",
        desc: "재무적인 안정성이 높은 회사",
        engName: "Wings of Balance",
        country: "대한민국",
        countryEng: "Korea",
        industry: "전체",
        industryEng: "All",
        standard: `투자 매력도 상위 7개\n높은 재무 안정성 중`,
        standardEng: "High Investment Attractiveness\nFrom High financial/stability",
        recommandTitle: "전략 설명",
        recommandTitleEng: "strategy",
        recommandSubtitle: "재무가 튼튼해야 좋은 회사지",
        recommandSubtitleEng: "Strong financials make a good company",
        recommandContentTitle: "균형의 날개 펼치기・FS7",
        recommandContentTitleEng: "Wings of Balance・FS7",
        recommandContentDesc1: "재무적으로 안정적인 기업 중 성장 가능성이 높은 매력적인 종목에 투자하는 전략입니다.",
        recommandContentDescEng1: "Invests in high-growth stocks among financially stable firms.",
        recommandContentDesc2: "안정성과 성장성을 함께 추구하며, 과도한 리스크를 피하고 싶은 투자자에게 적합합니다.",
        recommandContentDescEng2: "Balances stability and growth, ideal for investors avoiding excessive risk.",
        recommandType: "위험중립형",
        recommandTypeEng: "risk-neutral investors"
    }
}